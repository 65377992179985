import './import-jquery'
import 'bootstrap';

if (process.env.NODE_ENV === 'production') {
  // Google Tag Manager
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);

    // OneTrust Cookies Consent Notice start for ceteraholdings.com
    const oneTrustParent = d.getElementsByTagName(s)[0];
    const oneTrust = d.createElement("script");
    oneTrust.type = "text/javascript";
    oneTrust.setAttribute("data-domain-script", "ec2ceb69-04f7-4f0e-bf93-135ba0337317");
    oneTrust.async = "async";
    oneTrust.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    // document.head.appendChild(oneTrust);
    oneTrustParent.parentNode.insertBefore(oneTrust, oneTrustParent);

    function OptanonWrapper() {
    }

    // OneTrust Cookies Consent Notice end for ceteraholdings.com

  })(window, document, 'script', 'dataLayer', 'GTM-M5XSHFN');

  const snippet = document.createElement('noscript');

  // Set the HTML code for the snippet
  snippet.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M5XSHFN" height="0" width="0" style="display:none;visibility:hidden"></iframe>';

  // Insert the snippet right after the body tag
  document.body.insertBefore(snippet, document.body.firstChild);
  // End Google Tag Manager
}

if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'development') {
  // Google Tag Manager
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? '&l=' + l : '';


    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=Svu1cWsKlO974hp6ORS6OA&gtm_preview=env-4&gtm_cookies_win=x';
    f.parentNode.insertBefore(j, f);

    // OneTrust Cookies Consent Notice start for ceteraholdings.com
    const oneTrustParent = d.getElementsByTagName(s)[0];
    const oneTrust = d.createElement("script");
    oneTrust.type = "text/javascript";
    oneTrust.setAttribute("data-domain-script", "ec2ceb69-04f7-4f0e-bf93-135ba0337317-test");
    oneTrust.async = "async";
    oneTrust.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    // document.head.appendChild(oneTrust);
    oneTrustParent.parentNode.insertBefore(oneTrust, oneTrustParent);

    function OptanonWrapper() {
    }

    // OneTrust Cookies Consent Notice end for ceteraholdings.com

  })(window, document, 'script', 'dataLayer', 'GTM-M5XSHFN');

  const snippet = document.createElement('noscript');

  // Set the HTML code for the snippet
  snippet.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M5XSHFN&gtm_auth=Svu1cWsKlO974hp6ORS6OA&gtm_preview=env-4&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>';

  // Insert the snippet right after the body tag
  document.body.insertBefore(snippet, document.body.firstChild);
  // End Google Tag Manager
}


(function ($) {
  if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'development') {

    console.log('NODE_ENV: ', process.env.NODE_ENV)

    $('.gva-body-wrapper').append('<div class="container" id="ot-sdk-cookie-policy"></div>')

  }

})(jQuery);
